<template>
  <div>
    <van-nav-bar
      title="实名验证"
      :safe-area-inset-top="true"
      :fixed="true"
      :placeholder="true"
    />
    <div class="bodyCenter">
      <van-form @submit="onSubmit">
        <van-field
          clearable
          v-model="real_name"
          name="real_name"
          label="真实姓名"
          placeholder="请输入真实姓名"
          :rules="[{ required: true }]"
        />
        <van-field
          clearable
          v-model="mobile"
          name="mobile"
          label="手机号"
          placeholder="请输入手机号"
          :rules="[{ required: true }]"
        />
        <van-field
          clearable
          v-model="identity_card_number"
          name="identity_card_number"
          label="身份证"
          placeholder="请输入身份证号"
          :rules="[{ required: true }]"
        />
        <van-field
          name="fileList"
          label="照片上传"
          :rules="[{ required: true, message: '请上传图片' }]"
        >
          <template #input>
            <van-uploader v-model="fileList" :after-read="afterRead" />
          </template>
        </van-field>
        <div class="tipsContainer">
          <div class="tipItem">
            <div class="tip">*注意：</div>
            <div class="tipsText" @click="getViewingExamples">查看示例</div>
          </div>
          <div class="tipsTextItem">
            <div>1、</div>
            <div>需手持身份证照片，请确保照片清晰。</div>
          </div>
          <div class="tipsTextItem">
            <div>2、</div>
            <div>会自动打水印【仅供平台资料审核使用】，请放心上传。</div>
          </div>
        </div>
        <div style="margin: 30px 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
    <van-popup v-model="show" position="center" :get-container="getContainer">
      <van-image :src="imageUrl" fit="contain" />
    </van-popup>
  </div>
</template>

<script>
import { getUploadFile, getUserRevamp } from "@/api/homeapi";
import { getConfig } from "@/api/security";
export default {
  name: "ModifyRealName",
  data() {
    return {
      real_name: "",
      mobile: "",
      identity_card_number: "",
      token: "",
      fileList: [],
      show: false, // 控制弹出层显示/隐藏
      imageUrl: "",
    };
  },
  mounted() {
    this.token = this.$route.query.token;
    let configDetail = {
      key: "modify_real_name",
    };
    this.requestGetConfig(configDetail);
  },
  methods: {
    afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("type", "card");
      getUploadFile(formData)
        .then((res) => {
          if (res.status_code === 1000) {
            file.url = res.data.url;
            file.status = "success";
            file.message = "上传成功";
          } else {
            file.status = "failed";
            file.message = "上传失败";
          }
        })
        .catch(() => {
          file.status = "failed";
          file.message = "上传失败";
        });
    },
    getViewingExamples() {
      this.show = true;
    },
    getContainer() {
      return document.body;
    },
    onSubmit() {
      let img = this.fileList.reduce((prev, item) => {
        if (item.url) {
          prev.push(item.url);
        }
        return prev;
      }, []);
      if (img.length === 0) {
        this.$toast.fail("提交失败");
        return;
      }
      let config = {
        mobile: this.mobile,
        identity_card_number: this.identity_card_number,
        img: JSON.stringify(img),
        real_name: this.real_name,
        token: this.token,
      };
      if (this.token == undefined) {
        this.$toast.fail("提交失败");
        return;
      } else {
        this.requestGetUserRevamp(config);
      }
    },
    async requestGetConfig(configDetail) {
      try {
        const result = await getConfig(configDetail);
        if (result.code === 20000 || result.status_code === 1000) {
          this.imageUrl = result.data.content;
        } else {
          this.$toast.fail(result.message);
        }
      } catch (error) {
        this.$toast.fail("请求失败");
      }
    },
    async requestGetUserRevamp(configDetail) {
      try {
        const result = await getUserRevamp(configDetail);
        if (result.code === 20000 || result.status_code === 1000) {
          this.$router.replace("/blankpage");
        } else {
          this.$toast.fail(result.message);
        }
      } catch (error) {
        this.$toast.fail("请求失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.bodyCenter {
  min-height: 100vh;
}
.tipsContainer {
  width: 100%;
  padding: 16px 16px 0px 16px;
  box-sizing: border-box;
  // height: 44px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #888888;
  line-height: 22px;
  .tipItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tip {
      color: red;
    }
    .tipsText {
      color: #1989fa;
    }
  }
  .tipsTextItem {
    display: flex;
    margin-top: 5px;
  }
}
</style>
